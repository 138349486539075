@import url("https://fonts.googleapis.com/css?family=Merriweather:400,700,900&display=swap");

/* GENERAL */

html,
body {
  text-align: center;
  scroll-behavior: smooth;
}

.App {
  min-height: 100vh;
  background: url("./assets/music_background.jpg");
  background-position: center;
  background-size: 100%;
  background-repeat: repeat-y;
}

.main-container {
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 85px;
  padding-bottom: 50px;
}

.white-container {
  padding: 3%;
  width: fit-content;
  margin: auto;
  border: 2px solid rgba(0, 0, 0, 0.87);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
}

/* FONT SIZES */

.name {
  font-size: 72px;
  font-family: "Merriweather", serif;
  font-weight: 900;
}

.subheader {
  font-size: 36px;
  font-family: "Merriweather", serif;
  font-weight: 700;
}

.heading {
  font-size: 48px;
  font-family: "Merriweather", serif;
  font-weight: 700;
}

.subheading {
  font-size: 28px;
  font-family: "Merriweather", serif;
  font-weight: 700;
}

.small-subheading {
  font-size: 22px;
  font-family: "Merriweather", serif;
  font-weight: 700;
}

p,
.icons span {
  font-size: 20px;
}

p a,
p a:hover {
  text-decoration: underline;
}

.just {
  text-align: justify;
}

/* ICONS */

.home-icons i:hover,
.icons div:hover {
  transition: all 0.3s ease;
  transform: scale(1.1) translateY(-5px);
}

.home-icons {
  margin-bottom: 40px;
}

.icons {
  text-align: left;
  margin: auto;
  width: fit-content;
}

.icons div {
  margin-bottom: 20px;
}

svg,
i,
.icons a,
.icons a:hover {
  color: rgba(0, 0, 0, 0.87);
}

/* IFRAMES - VIDEO & RESUME */

.resume {
  width: 100%;
  height: 100vh;
  margin-top: 40px;
}

.ui.black.big.button {
  font-family: "Merriweather", serif;
  font-weight: 400;
}

iframe {
  border: none;
  width: 560px;
  height: 315px;
}

/* COMPOSITIONS */

.comp-grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 20px;
}

#compositions-menu {
  position: fixed;
  border: 2px solid rgba(0, 0, 0, 0.87);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
}

#compositions-menu:hover {
  background: rgba(255, 255, 255, 1);
}

.ui.vertical.menu .item {
  font-size: 15px;
}

.ui.vertical.menu a {
  color: rgba(0, 0, 0, 0.87);
}

/* accounts for scrolling to element with fixed header */
.comp-grid h1::before,
.comp-grid h2::before {
  display: block;
  content: "";
  margin-top: -70px;
  height: 70px;
  visibility: hidden;
  pointer-events: none;
}

.comp-section {
  margin-bottom: 40px;
}

.composition {
  margin-bottom: 30px;
}

/* RECOGNITION */

.rec-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.rec-grid > div {
  padding: 5%;
  border: 2px solid rgba(0, 0, 0, 0.87);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* PHOTOS */

.ReactGridGallery {
  overflow: auto;
}

.home-photo {
  width: 512px;
}

/* sets width of images of different sizes */
.photo-small {
  width: 35%;
}

.photo-medium {
  width: 50%;
}

.photo-large {
  width: 75%;
}

/* HEADER */

.item {
  font-family: "Merriweather", serif;
  font-weight: 400;
}

/* sets mobile hamburger menu icon to not display on desktop */
#hamburger-menu {
  display: none;
}

#hamburger-menu a {
  justify-content: left;
  padding-left: 20px;
}

#hamburger-menu i {
  color: white;
  padding-left: 20px;
}

/* MEDIA QUERIES */

/* when menu starts to stack */
@media only screen and (max-width: 900px) {
  /* hide menu and add margin */
  .ui.black.massive.inverted.stackable.top.fixed.seven.item.menu {
    display: none;
    margin-top: 43px;
  }

  .ui.black.massive.inverted.stackable.top.fixed.seven.item.menu a {
    width: 100%;
  }

  .photo-large {
    width: 100%;
  }

  /* show mobile hamburger menu icon */
  #hamburger-menu {
    display: flex;
  }

  /* makes recognition grid 2 cols */
  .rec-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .App {
    background-position: center top;
  }
}

/* MOBILE SCREEN WIDTHS */
@media only screen and (max-width: 600px) {
  /* stacks recognition instead of 3 col grid */
  .rec-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .home-photo {
    width: 95%;
  }

  /* makes videos smaller on mobile */
  iframe {
    width: 100%;
    height: auto;
  }
}

/* DESKTOP SCREEN WIDTHS */
@media only screen and (min-width: 901px) {
  /* reshow full menu on resize of window */
  .ui.black.massive.inverted.stackable.top.fixed.seven.item.menu {
    display: flex !important;
  }

  .ui.black.massive.inverted.stackable.top.fixed.seven.item.menu a {
    width: 14.285%;
  }
}

/* compositions - when need to hide table of contents */
@media only screen and (max-width: 1068px) {
  /* hide table of contents */
  #compositions-menu {
    display: none;
  }

  /* recenter compositions */
  .comp-grid {
    display: block;
  }
}

/* MUSIC NOTE ANIMATION */
/* https://codepen.io/MaryG/pen/wJMMdw */

.notes {
  display: block;
  margin: 15px auto;
  position: relative;
  width: 50%;
  min-width: 300px;
  height: 100px;
  border: 0px solid #000;
}

.note-1,
.note-2,
.note-3,
.note-4 {
  position: absolute;
  animation: notes 2s infinite linear;
  font-size: 35px;
  opacity: 0;
}

.note-1 {
  top: 60px;
  left: 0;
  animation-delay: 0.5s;
}

.note-2 {
  top: 30px;
  left: 30%;
  animation-delay: 1s;
}

.note-3 {
  top: 90px;
  left: 60%;
  animation-delay: 1.5s;
}

.note-4 {
  top: 40px;
  left: 90%;
  animation-delay: 2s;
}

@keyframes notes {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1.5) translate(50%, -50%);
  }
  80% {
    opacity: 0;
    transform: scale(1.5) translate(100%, -100%);
  }
  100% {
    transform: scale(1.5) translate(100%, -100%);
    opacity: 0;
  }
}
